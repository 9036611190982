<template>
  <div class="text-center">
    <v-dialog
      v-model="modal.show"
      width="500"
    >
      <v-card :modal-open="modal">
        <v-card-title class="text-h6 grey lighten-2">
          {{ modal.title }}
        </v-card-title>

        <v-card-text>
          <span v-html="modal.content">{{ modal.content }}</span>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="modal.chatButton"
            outlined
            @click="startChat"
          >
            Contact Us
          </v-btn>
          <v-spacer></v-spacer>
          <router-link
            v-if="modal.link"
            :to="modal.link"
          >
            <v-btn
              color="primary"
              outlined
              text
            >
              Continue
            </v-btn>
          </router-link>
          <v-btn
            v-else
            color="primary"
            outlined
            @click="modal.show = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modal: {
      show: Boolean,
      title: String,
      content: String,
      link: String,
      data: Array,
      chatButton: Boolean,
    },
  },
  methods: {
    startChat() {
      this.modal.show = false
      window.$crisp?.push(['do', 'chat:open'])
    },
  },
}
</script>
<style>
</style>
