<template>
  <div class="leagues">
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>
            <div class="page-title text-center mx-auto">
              <h2
                class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center"
              >
                <span class="me-2">Leagues</span>
              </h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="text-center mb-2">
              <p>
                Create or join a league, invite friends and compete with other fans from around the
                world.
              </p>
            </div>
            <template>
              <div class="text-center pb-12">
                <div v-if="!team">
                  <p>You need to create a team before you can join a league.</p>
                  <a href="/edit-team">
                    <v-btn
                      outlined
                      color="success"
                    >Create Team
                    </v-btn>
                  </a>
                </div>
                <div v-else>
                  <v-row>
                    <v-col
                      cols="12"
                      class="col-md-3 col-xs-12 col-sm-12"
                    >
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="pt-0 mt-0 ml-4"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="2"
                      class="hidden-sm-and-down"
                    >
                      <v-checkbox
                        v-model="strikeLeague"
                        label="Strike PowerUps"
                        class="ml-2 mt-1 text-xs"
                        small
                        color="success"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      class="hidden-sm-and-down"
                    >
                      <v-checkbox
                        v-model="showJoined"
                        label="My Leagues"
                        class="ml-2 mt-1 text-xs"
                        small
                        color="success"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      class="hidden-sm-and-down"
                    >
                      <v-checkbox
                        v-model="showmainEventOnly"
                        label="Main Event Only"
                        class="ml-2 mt-1 text-xs"
                        small
                        color="success"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      class="col-md-3 col-xs-12 col-sm-12 text-center"
                    >
                      <router-link to="/edit-league" class="create-league">
                        <v-btn
                          outlined
                          small
                          class="text-sm ma-0 mt-1 mr-2"
                        >
                          Create a League
                        </v-btn>
                        <div class="hidden-md-and-up mb-4">
                        </div>
                      </router-link>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="headers"
                    :loading="tableLoading"
                    :items="leaguesList"
                    :sort-by="['-joined', '-createdBy', ]"
                    must-sort
                    item-key="index"
                    class="elevation-1"
                    :search="search"
                    mobile-breakpoint="0"
                  >
                    <!-- name -->
                    <template v-slot:item.name="{ item }">
                      <v-row class="ml-1">
                        <v-col
                          cols="5"
                          class="text-center px-0 mx-0"
                        >
                          <v-avatar
                            rounded
                            :size="gAvatar"
                            :height="gAvatar"
                            color="white"
                            class="white--text font-weight-medium mt-3 cursor-pointer"
                            @click="viewLeague(item)"
                          >
                            <v-img
                              class="text-center justify-center d-all"
                              :height="gAvatar"
                              :max-width="gAvatar"
                              lazy-src="https://www.fantasykombat.com/android-chrome-192x192.png"
                              :src="item.logo || 'https://www.fantasykombat.com/android-chrome-192x192.png'"
                            />
                          </v-avatar>
                          <br>
                          <v-btn-toggle class="ma-1 pa-1 text-xs">
                            <span
                              v-if="item.partner"
                              class="mr-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ icons.mdiShieldStarOutline }}
                                  </v-icon>
                                </template>
                                <span> Verified League</span>
                              </v-tooltip>
                            </span>
                            <span
                              v-if="item.strikeLeague"
                              class="mr-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ icons.mdiExpandAllOutline }}
                                  </v-icon>
                                </template>
                                <span> Strike League</span>
                              </v-tooltip>
                            </span>
                            <span
                              v-if="item.mainEventOnly"
                              class="mx-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ icons.mdiListStatus }}
                                  </v-icon>
                                </template>
                                <span> Main card only league</span>
                              </v-tooltip>
                            </span>
                            <span
                              v-else
                              class="mr-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ icons.mdiPlaylistCheck }}
                                  </v-icon>
                                </template>
                                <span> Main Card & All Prelims</span>
                              </v-tooltip>
                            </span>
                            <span
                              v-if="item.joined"
                              class="mr-1"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ icons.mdiAccountMultipleCheckOutline }}
                                  </v-icon>
                                </template>
                                <span>My League</span>
                              </v-tooltip>
                            </span>
                          </v-btn-toggle>
                        </v-col>
                        <v-col
                          cols="7"
                          class="mb-0 ml-0 pl-0"
                        >
                          <div
                            class="font-weight-semibold mt-2 cursor-pointer"
                            @click="viewLeague(item)"
                          >
                            <span class="text--primarytext-truncate">{{
                                item.name
                              }}</span>
                          </div>
                          <v-list-item-subtitle class="text-xs text-truncate">
                            <span v-if="item.desc">{{ item.desc }}</span>
                          </v-list-item-subtitle>
                          <div class="league-btns mt-3">
                            <v-btn
                              v-if="!item.joined"
                              outlined
                              class="ml-0 mb-2"
                              small
                              @click="joinLeague(item)"
                            >
                              <v-icon
                                v-if="item.visibility !=='Public' && joinCode !== `${item.id}-${item.inviteCode}` && !item.joined"
                                small
                                class="mr-1"
                              >
                                {{ icons.mdiLockOutline }}
                              </v-icon>
                              <v-icon
                                v-if="joinCode == `${item.id}-${item.inviteCode}`|| item.joined"
                                small
                                class="mr-1"
                              >
                                {{ icons.mdiLockOpenVariantOutline }}
                              </v-icon>
                              Join
                            </v-btn>
                            <v-btn
                              v-if="item.joined"
                              outlined
                              class="ml-1 mb-2"
                              small
                              color="success"
                              @click="viewLeague(item)"
                            >
                              View
                            </v-btn>
                            <v-btn
                              v-if="item.createdBy === user.uid"
                              outlined
                              class="ml-1 mb-2"
                              small
                              @click="viewLeague(item)"
                            >
                              Admin
                            </v-btn>
                            <div v-if="admin">
                              owner:{{ item.createdBy }}<br>
                              user:{{ user.uid }}<br>
                              admins: {{ item.admins }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.index="{ item}" class="ranks text-center">
                      <div>
                        <v-chip
                          class="rank-chip"
                          color="grey"
                          :class="{ 'silver': item.index <= 15, 'gold': item.index <=10 }"
                          small
                        >
                          <v-icon
                            v-if="item.index===0"
                            small
                          >
                            {{ icons.mdiCrownOutline }}
                          </v-icon>
                          {{ item.index }}
                        </v-chip>
                      </div>
                    </template>
                    <template v-slot:item.totalPoints="{ item }">
                      <div
                        v-if="item.totalPoints==0 && item.visibility
                          !== 'Public'"
                      >
                        <v-icon>{{ icons.mdiEyeOffOutline }}</v-icon>
                      </div>
                      <div
                        v-else
                      >
                        {{ item.totalPoints }}
                      </div>
                    </template>
                    <template v-slot:item.lastPoints="{ item }">
                      <div
                        v-if="item.lastPoints==0 && item.visibility
                          !== 'Public'"
                      >
                        <v-icon>{{ icons.mdiEyeOffOutline }}</v-icon>
                      </div>
                      <div
                        v-else
                      >
                        {{ item.lastPoints }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Modal
      :modal="modal"
      class="mt-10"
    ></Modal>
    <v-snackbar
      v-model="snackbarShow"
      :color="snackbarType"
      :fixed="true"
      :top="true"
      :timeout="snackbarTimeout"
    >
      <v-layout
        align-center
        pr-4
      >
        <v-icon
          class="pr-3"
          dark
          large
        >
          {{ snackbarIcon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbarText }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiCrownOutline,
  mdiEarth,
  mdiLockOutline,
  mdiLockOpenVariantOutline,
  mdiEyeOffOutline,
  mdiShieldStarOutline,
  mdiExpandAllOutline,
  mdiPlaylistCheck,
  mdiListStatus,
  mdiAccountMultipleCheckOutline,
} from '@mdi/js'
import dbFunctions from '@/db'
import Modal from '@/views/components/Modal.vue'

export default {
  components: {
    Modal,
  },
  data() {
    return {
      user: this.$store.state.user,
      admin: this.$store.state.user?.profile?.admin,
      isMobile: false,
      search: '',
      strikeLeague: false,
      showmainEventOnly: false,
      showJoined: false,
      leagues: [],
      gAvatar: 80,
      loadedLeagues: [],
      team: [],
      tableLoading: true,
      joinCode: null,
      modal: {
        title: '',
        content: '',
        show: false,
      },
      snackbarText: 'test',
      snackbarType: 'success',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',
      icons: {
        mdiCrownOutline,
        mdiEarth,
        mdiLockOutline,
        mdiLockOpenVariantOutline,
        mdiEyeOffOutline,
        mdiShieldStarOutline,
        mdiExpandAllOutline,
        mdiPlaylistCheck,
        mdiListStatus,
        mdiAccountMultipleCheckOutline,
      },
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Name', align: 'start', value: 'name'},
        {text: 'Rank', value: 'index'},
        {text: 'Total', value: 'totalPoints'},
        {text: 'Latest', value: 'lastPoints'},

        // {text: 'Country', value: 'country'},
        // {text: 'Teams', value: 'teams'},
        // {text: '', value: 'visibility'},

      ]
    },
    leaguesList() {
      if (this.strikeLeague) {
        return this.leagues.filter(i => i.strikeLeague)
      }
      if (this.showJoined) {
        return this.leagues.filter(i => i.joined)
      }
      if (this.showmainEventOnly) {
        return this.leagues.filter(i => i.mainEventOnly)
      }

      return this.leagues
    },
  },
  mounted() {
    this.isMobile = this.$parent.$parent.$parent.$parent.$parent.isMobile // app.js
    if (this.isMobile) {
      this.gAvatar = 45
    }
    this.checkLeagueCodes()
    this.getTeams()
    this.getLeagues()
  },
  methods: {
    checkLeagueCodes() {
      this.joinCode = this.$cookies.get('joincode')
      if (this.joinCode) {
        if (this.joinCode.length > 4) {
          // eslint-disable-next-line prefer-destructuring
          this.joinCode = this.joinCode.split('-')[0]
        }
      }
    },
    joinLeagueViaCode() {
      this.team.leagues.push(this.justJoined.id)
      this.team.leagues = [...new Set(this.team.leagues)]
      dbFunctions.updateTeam(this.user.uid, {leagues: this.team.leagues})
        .then(() => {
          this.modal.title = 'You\'ve been accepted into a league!'
          this.modal.content = `<div class="text-center mx-auto"><img  height="100" class="rounded league-added-logo  mt-5 ma-2" src="${this.justJoined.logo}"/><br>
            <h3> ${this.justJoined.name} </h3></div><br>`
          this.modal.show = true
          this.modal.link = `/view-league?id=${this.justJoined.id}`

          this.$cookies.set('joincode', null)
          this.joinCode = null
        })
    },
    async getLeagues() {
      this.leagues = []
      await dbFunctions.getLeagues('active')
        .then(data => {
          const leagues = data.sort((a, b) => (a.totalPoints || 0) - (b.totalPoints || 0)).reverse()
          for (let i = 0; i < leagues.length; i += 1) {
            leagues[i].index = i + 1
            if (this.team?.leagues.includes(leagues[i].id)) {
              leagues[i].joined = true
            }
            if (this.joinCode) {
              if (leagues[i].id === this.joinCode) {
                this.justJoined = leagues[i]
                this.joinLeagueViaCode()
              }
            }
            this.leagues.push(leagues[i])
          }
          this.joinCode = false
          this.tableLoading = false
        })
    },
    joinCodePrompt(item) {
      this.modal.show = true
      this.modal.title = `${item.visibility} League`
      this.modal.content = `${item.visibility} Leagues require a invite code to join.<br> <input type="text" id="league-code-input" id="leagueCode" name="leagueCode">`
      const cookies = this.$cookies
      const $this = this
      setTimeout(() => {
        if (document.getElementById('league-code-input')) {
          document.getElementById('league-code-input').addEventListener('change', function () {
            if (this.value.length > 4) {
              // eslint-disable-next-line prefer-destructuring
              $this.joinCode = this.value.split('-')[1]
              if ($this.joinCode === item.inviteCode) {
                $this.justJoined = item
                cookies.set('joincode', this.value)
                $this.joinLeagueViaCode()
              } else {
                $this.snackbarText = 'Invalid Code'
                $this.snackbarType = 'warning'
                $this.snackbarTimeout = 5000
                $this.snackbarShow = true
                $this.snackbarIcon = $this.icons.mdiLockOutline
              }
            }
          })
        }
      }, 1100)
    },
    viewLeague(item) {
      if (item.joined || item.createdBy === this.user.uid) {
        this.$router.push({path: '/view-league', query: {id: item.id, rank: item.index}})
      } else if (item.visibility === 'Public' || this.joinCode === `${item.id}-${item.inviteCode}`) {
        this.$router.push({path: '/view-league', query: {id: item.id, rank: item.index}})
      } else {
        this.joinCodePrompt(item)
      }
    },

    joinLeague(item) {
      if (this.loadedLeagues?.length > 6) {
        this.snackbarText = 'You have reached your 6 league limit.'
        this.snackbarType = 'warning'
        this.snackbarTimeout = 5000
        this.snackbarShow = true
        this.snackbarIcon = this.icons.mdiLockOutline
      } else if (item.visibility === 'Public') {
        this.saveLeague(item.id)
      } else if (this.joinCode === `${item.id}-${item.inviteCode}` || item.createdBy === this.user.uid) {
        this.saveLeague(item.id)
      } else {
        this.joinCodePrompt(item)
      }
    },
    async getTeams() {
      await dbFunctions.getTeam(this.user.uid)
        .then(teamData => {
          this.team = teamData
          if (this.team?.leagues) {
            if (this.team?.leagues?.length > 0) {
              this.joinedLeague = true
              for (let i = 0; i < this.team.leagues.length; i += 1) {
                dbFunctions.getMyLeagues(this.team.leagues[i])
                  .then(data => {
                    const ldata = data.data()
                    if (ldata) {
                      ldata.id = data.id
                      if (ldata.status === 'active') {
                        this.loadedLeagues.push(ldata)
                      }
                    }
                  })
              }
            }
          }
        })
    },
    saveLeague(id) {
      this.team.leagues.push(id)
      this.team.leagues = [...new Set(this.team.leagues)]
      dbFunctions.updateTeam(this.user.uid, {leagues: this.team.leagues})
        .then(() => {
          this.$router.push({path: '/view-league', query: {id}})
        })
    },
  }
  ,
}
</script>

<style>

.mobile .leagues td.text-start {
  padding: 0px !important;
}

.mobile a.create-league {
  margin-top: -31px;
  display: block;
}

input#league-code-input {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  color: #fff;
}

p.league-desc {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  max-width: 220px;
  text-align: center;
  margin: auto;
  display: inline-block;
  font-size: 10px;
}

.mobile .league-btns {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mobile .league-btns .v-btn--outlined.theme--dark.v-size--default {
  width: 50px !important;
  /* margin-bottom: 10px; */
}

.mobile .league-logo {
  margin: auto;
  display: block;
  position: relative;
  min-width: 205px;
}

.mobile .leagues .v-card__text {
  padding: 0;
}

.mobile .leagues .v-list.v-sheet.theme--dark {
  text-align: left !important;
  position: relative;
  width: 272px;
  margin: auto;
}

.mobile .leagues .font-weight-semibold {
  text-align: left;
}

.leagues .v-list-item__subtitle.text-xs {
  max-width: 216px;
  overflow: auto;
  white-space: normal;
}

.mobile .leagues .v-list-item__subtitle.text-xs {
  max-width: 185px;
  overflow: auto;
  white-space: normal;
}
</style>
